import { ONE_MB, FILE_UPLOAD_MAX_FILE_SIZE } from "@bigpi/cookbook";
import { hasRolePermission, Permissions } from "@bigpi/permission";
import { useAuthUser } from "@frontegg/react";
import { Box, Button, Typography } from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import { useValue } from "@tldraw/tldraw";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { LibraryGrid, LibraryGridSize } from "./LibraryGrid";
import { LibraryList } from "./LibraryList";
import { useFilesQuery } from "GraphQL/Generated/Apollo";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
interface ILibraryViewProps {
  gridSize: LibraryGridSize;
  isSelectionEnabled: boolean;
  onSelectionChange: (id: string) => void;
  searchValue?: string;
  selectedIds: Array<string>;
  viewType: "list" | "grid";
  onClick: (id: string) => void;
  onGridSizeChange: (gridSize: LibraryGridSize) => void;
}

const DEFAULT_PAGE_SIZE = 20;

// *********************************************
// Component
// *********************************************/
export function LibraryView(props: ILibraryViewProps) {
  const { t } = useTranslation();
  const user = useAuthUser();
  const { gridSize, isSelectionEnabled, viewType, selectedIds, onClick, onSelectionChange, searchValue, onGridSizeChange } =
    props;

  // State
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<Record<string, string>>({ updatedAt: "desc" });

  // Files query
  const {
    data: filesData,
    fetchMore: fetchMoreFiles,
    loading,
    error,
  } = useFilesQuery({
    variables: {
      limit: DEFAULT_PAGE_SIZE,
      offset,
      orderBy,
      filters: {
        searchTerm: searchValue,
      },
    },
  });

  // Hooks
  const rows = useValue(
    "filesRows",
    () => {
      return filesData?.files || [];
    },
    [filesData],
  );

  const count = useValue(
    "filesCount",
    () => {
      return filesData?.fileAggregate.count || 0;
    },
    [filesData],
  );

  if (!searchValue && !loading && rows.length === 0 && hasRolePermission(user.permissions, Permissions.PatronLibraryWrite)) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          minHeight: "300px",
        }}
      >
        <UploadFile sx={{ fontSize: "xx-large", mb: 2, color: "#0b3d62" }} />
        {t("Components.LibraryUploadDialog.DragAndDrop")}
        <Typography variant="body1" color="GrayText">
          {t("Components.LibraryUploadDialog.FileTypeAndSize", {
            maxInMBs: FILE_UPLOAD_MAX_FILE_SIZE / ONE_MB,
          })}
        </Typography>
      </Box>
    );
  } else if (rows.length === 0 && !loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          minHeight: "300px",
        }}
      >
        <Typography variant="body1" color="GrayText">
          {t("Pages.Library.List.NoItemsFound")}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Box sx={{ display: "flex", p: 3, justifyContent: "center" }}>
        {viewType === "list" ? (
          <LibraryList
            isSelectionEnabled={isSelectionEnabled}
            onClick={onClick}
            rows={rows}
            loading={loading}
            error={error}
            selectedIds={selectedIds}
            onSelectionChange={onSelectionChange}
          />
        ) : (
          <LibraryGrid
            isSelectionEnabled={isSelectionEnabled}
            onClick={onClick}
            gridSize={gridSize}
            rows={rows}
            loading={loading}
            error={error}
            selectedIds={selectedIds}
            onSelectionChange={onSelectionChange}
            onGridSizeChange={onGridSizeChange}
          />
        )}
      </Box>

      {count > rows.length && (
        <Button
          sx={{ alignSelf: "center", maxWidth: "150px" }}
          onClick={() => {
            fetchMoreFiles({
              variables: {
                offset: rows.length,
                filters: {
                  searchTerm: searchValue,
                },
              },
            });
          }}
        >
          {t("Pages.Library.List.ShowMore")}
        </Button>
      )}
    </Box>
  );
}
