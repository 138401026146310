import { Card, Grid, CardHeader, ButtonBase, Typography, Checkbox } from "@mui/material";

import { DocumentIcon } from "Components/DocumentIcon/DocumentIcon";
import { DocumentsQuery } from "GraphQL/Generated/Apollo";
import { DocumentsListMenu } from "./DocumentsListMenu";

interface IDocumentsGridItemProps {
  document: DocumentsQuery["documents"][0];
  isSelectionEnabled: boolean;
  size: "small" | "medium" | "large";
  checked: boolean;
  onSelectionChange: (id: string) => void;
  onClick: (id: string) => void;
}

export function DocumentsGridItem(props: IDocumentsGridItemProps) {
  const { document, checked, isSelectionEnabled, onClick, onSelectionChange, size } = props;

  let actionComponent: JSX.Element | null = null;

  if (isSelectionEnabled) {
    actionComponent = <Checkbox checked={checked} onChange={() => onSelectionChange(document.id)} />;
  } else {
    actionComponent = <DocumentsListMenu documentId={document.id} />;
  }

  return (
    <Grid
      item
      xl={size === "small" ? 3 : size === "medium" ? 4 : 6}
      lg={size === "small" ? 2 : size === "medium" ? 3 : 4}
      md={size === "small" ? 3 : size === "medium" ? 4 : 6}
      xs={size === "small" ? 6 : size === "medium" ? 6 : 12}
      sx={{ display: "grid" }}
    >
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderWidth: "2px",
          backgroundColor: "#e8eaeb",
          ":hover": {
            backgroundColor: "#d8dadb",
          },
        }}
      >
        <CardHeader
          avatar={<DocumentIcon />}
          title={
            <ButtonBase sx={{ textAlign: "start" }} disableRipple onClick={() => onClick(document.id)}>
              <Typography
                sx={{
                  textAlign: "start",
                  fontSize: size === "small" ? "1rem" : size === "medium" ? "1.1rem" : "1.2rem",
                  fontWeight: "500",
                  wordBreak: "break-word",
                }}
              >
                {document.name}
              </Typography>
            </ButtonBase>
          }
          action={actionComponent}
        />
      </Card>
    </Grid>
  );
}
