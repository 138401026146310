import { useApolloClient } from "@apollo/client";
import { FileUploadStatus } from "@bigpi/cookbook";
import { useCallback, useEffect, useState } from "react";

import { useUploadFilesBatch } from "Components/Upload/Hooks/useUploadFilesBatch";
import { useUploadFilesMutation } from "GraphQL/Generated/Apollo";
import { FILE_UPLOAD_SUBSCRIPTION } from "GraphQL/Upload/Subscription";
import { FileStatus, IFileWithId } from "Components/Upload/FilesList";

export function useLibraryFilesUploadBatch() {
  const [filesStatusMap, setFilesStatusMap] = useState<Record<string, FileStatus>>({});
  const [progressingFiles, setProgressingFiles] = useState<Record<string, number>>({});
  const client = useApolloClient();

  const onUpdateFilesStatus = useCallback((filesToUpdate: Record<string, FileStatus>) => {
    setFilesStatusMap((prev) => {
      return { ...prev, ...filesToUpdate };
    });
  }, []);

  useEffect(() => {
    // Subscribe to the uploading progress of the files
    const clientSubscribe = client.subscribe({ query: FILE_UPLOAD_SUBSCRIPTION }).subscribe({
      next: (response) => {
        const addFileResponse = response.data.onFileAdded;
        if (addFileResponse) {
          const fileId = addFileResponse.id;
          if (addFileResponse.uploadStatus === FileUploadStatus.Running) {
            setProgressingFiles({
              [fileId]: addFileResponse.uploadedSize / addFileResponse.totalSize,
            });
          } else if (addFileResponse.uploadStatus === FileUploadStatus.Success) {
            onUpdateFilesStatus({ [fileId]: FileStatus.Success });
          } else if (addFileResponse.uploadStatus === FileUploadStatus.Failure) {
            // TODO: should we set the fail status
            onUpdateFilesStatus({ [fileId]: FileStatus.Failed });
          }
        }
      },
    });

    return () => {
      clientSubscribe.unsubscribe();
    };
  }, []);

  const [uploadLibraryFiles] = useUploadFilesMutation();
  const { abortUpload, uploadFilesBatch } = useUploadFilesBatch(
    onUpdateFilesStatus,
    (variables) => uploadLibraryFiles(variables),
    ["Files"],
  );

  // Upload files in batch
  const uploadFiles = useCallback(
    async (files: Array<IFileWithId>, bundleId?: string) => {
      let currentSize = 0;
      const batchSize = 5;
      let batchFiles = files.slice(currentSize, batchSize);
      while (batchFiles.length > 0) {
        await uploadFilesBatch(batchFiles, { bundleId });
        currentSize += batchSize;
        batchFiles = files.slice(currentSize, currentSize + batchSize);
      }
    },
    [uploadFilesBatch],
  );

  return {
    abortUpload,
    filesStatusMap,
    progressingFiles,
    uploadFiles,
  };
}
