import { ApolloError } from "@apollo/client";
import { List, ListItem, ListItemButton, ListItemText, ListItemIcon, Typography, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DocumentIcon } from "Components/DocumentIcon/DocumentIcon";
import { DocumentsQuery } from "GraphQL/Generated/Apollo";
import { DocumentsListMenu } from "./DocumentsListMenu";

interface IDocumentsListProps {
  isSelectionEnabled: boolean;
  loading: boolean;
  rows: DocumentsQuery["documents"];
  error?: ApolloError;
  selectedIds: Array<string>;
  onSelectionChange: (id: string) => void;
  onClick: (id: string) => void;
}

export function DocumentsList(props: IDocumentsListProps) {
  const { error, isSelectionEnabled, loading, rows, selectedIds, onClick, onSelectionChange } = props;
  const { t } = useTranslation();

  return (
    <>
      {loading && <Typography variant="caption">{t("Global.Status.Loading")}</Typography>}
      {!loading && rows.length > 0 && (
        <List
          component="nav"
          aria-label={t("Pages.Documents.List.AvailableDocuments")}
          sx={{ maxWidth: "960px", display: "flex", flexDirection: "column", flex: 1 }}
        >
          {rows.map((row) => {
            return (
              <ListItem key={row.id} secondaryAction={<DocumentsListMenu documentId={row.id} />} disablePadding>
                {isSelectionEnabled && (
                  <ListItemIcon>
                    <Checkbox checked={selectedIds.includes(row.id)} onChange={() => onSelectionChange(row.id)} />
                  </ListItemIcon>
                )}

                <ListItemButton sx={{ cursor: "default" }} disableRipple onClick={() => onClick(row.id)}>
                  <ListItemIcon>
                    <DocumentIcon />
                  </ListItemIcon>

                  <ListItemText primary={row.name} primaryTypographyProps={{ fontWeight: "500" }} sx={{ cursor: "pointer" }} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
      {!loading && !error && rows.length === 0 && (
        <Typography variant="caption" color="textSecondary">
          {t("Pages.Documents.List.NoItems")}
        </Typography>
      )}
      {error && (
        <Typography variant="caption" color="error.main">
          {t("Pages.Documents.List.LoadError")}
        </Typography>
      )}
    </>
  );
}
