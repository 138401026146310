import { FileSourceType } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Paper, PaperProps, Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";

import { Config } from "Config";
import { useFilePreviewData } from "./Hooks/useFilePreviewData";

import "./FilePreviewDialog.css";

interface IFilePreviewDialogProps {
  fileId: string;
  fileType: FileSourceType;
  onClose: () => void;
}

export function FilePreviewDialog(props: IFilePreviewDialogProps) {
  const { fileId, fileType, onClose } = props;
  const user = useAuthUser();
  const { t } = useTranslation();

  // State
  const [height, setHeight] = useState(800);
  const [width, setWidth] = useState(1024);
  const [isPreviewAuthComplete, setIsPreviewAuthComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const accessToken = user?.accessToken;

  // Get the file preview details
  const { fileName, url } = useFilePreviewData(fileId, fileType);

  // Call the preview endpoint to set a cookie there
  useEffect(() => {
    if (accessToken) {
      const headers = new Headers({
        authorization: `Bearer ${accessToken}`,
      });

      // Make sure we're authenticated against the collaboration backend
      fetch(`${Config.previewHttpUrl}/preview-auth`, {
        credentials: "include",
        headers,
      }).then(
        (result) => {
          if (result.ok) {
            setIsPreviewAuthComplete(true);
            setErrorMessage(null);
          } else {
            console.error("Error authenticating for preview", result.status, result.statusText);
            setErrorMessage(t("Components.PreviewDialog.AuthError"));
          }
        },
        (e) => {
          console.error("Error authenticating for preview", e);
          setErrorMessage(t("Components.PreviewDialog.AuthError"));
        },
      );
    }
  }, [accessToken]);

  return (
    <Dialog onClose={onClose} open={true} maxWidth={false} aria-labelledby="preview-dialog-title" PaperComponent={PaperComponent}>
      <Resizable
        height={height}
        width={width}
        minConstraints={[400, 400]}
        onResize={(event, data) => {
          setHeight(data.size.height);
          setWidth(data.size.width);
        }}
      >
        <>
          <DialogTitle id="preview-dialog-title" style={{ cursor: "move" }}>
            <Stack direction="row" justifyContent="space-between">
              <span>{fileName}</span>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent dividers={true} sx={{ height: `${height}px`, width: `${width}px`, display: "flex" }}>
            {isPreviewAuthComplete ? (
              <div style={{ display: "flex", flex: 1, position: "relative" }}>
                {/* This fixes issue when mouse stops resizing over iframe */}
                <div className="draggable-iframe-cover" />
                <iframe src={url} style={{ border: "unset", flex: 1 }} />
              </div>
            ) : (
              <Box sx={{ flex: 1 }}>
                <Skeleton variant="rectangular" width="8.5in" height="100%" sx={{ margin: "auto" }} />
              </Box>
            )}
          </DialogContent>
        </>
      </Resizable>
    </Dialog>
  );
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#preview-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
