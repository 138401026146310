import { useHashParams } from "@bigpi/cutlery";
import { useAuthUser } from "@frontegg/react";
import { Box } from "@mui/material";
import { Editor, TLShapeId } from "@tldraw/tldraw";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { BoardDatastore, BoardDatastoreContext } from "BoardComponents/BoardDatastore";
import { WorkspaceBoardChatActionManager } from "Chat/ChatActionManagers/WorkspaceBoard/WorkspaceBoardChatActionManager";
import { WorkspaceBoardCommandManager } from "Components/CommandManagers/WorkspaceBoard/WorkspaceBoardCommandManager";
import { useIsWorkspaceEditable } from "Components/Workspace/Hooks/useIsWorkspaceEditable";
import { CommandContext } from "CommandContext";
import { Config } from "Config";
import { useImageTokenPolling } from "Editor/Extensions/PasteContent/useImageTokenPolling";
import { useWorkspaceBoardUserPreferencesQuery, useWorkspaceQuery } from "GraphQL/Generated/Apollo";
import { WORKSPACE_BOARD_USER_PREFERENCES_KEY } from "GraphQL/UserPreference";
import { CollaborativeBoard, CollaborativeBoardConfig } from "TldrawBoard/CollaborativeBoard";
import { getRandomColor } from "Utils/ColorUtils";
import { POSITION_SEARCH_PARAM_NAME, SHAPE_ID_SEARCH_PARAM_NAME } from "./Constants";

import "./WorkspaceBoard.css";

export interface WorkspaceBoardProps {
  workspaceBoardId: string;
  workspaceId: string;
}

export function WorkspaceBoard(props: WorkspaceBoardProps) {
  const { workspaceBoardId, workspaceId } = props;

  const { getHashParam } = useHashParams();

  const { t } = useTranslation();

  const user = useAuthUser();

  const [tldrawEditor, setTldrawEditor] = useState<Editor>();
  const { isEditable: isWorkspaceEditable, loading: aclLoading } = useIsWorkspaceEditable(workspaceId);

  const shapeIdFromUrl = getHashParam(SHAPE_ID_SEARCH_PARAM_NAME) || "";
  const positionFromUrl = getHashParam(POSITION_SEARCH_PARAM_NAME) || "";
  const [xFromUrl, yFromUrl, zFromUrl] = positionFromUrl.split(",").map((v) => parseFloat(v));

  const { data, loading, error } = useWorkspaceQuery({ variables: { id: workspaceId } });
  const { data: workspaceBoardPreferences } = useWorkspaceBoardUserPreferencesQuery({
    variables: {
      key: WORKSPACE_BOARD_USER_PREFERENCES_KEY(workspaceBoardId),
    },
  });

  useEffect(() => {
    document.documentElement.style.overscrollBehaviorX = "contain";
    document.body.style.overscrollBehaviorX = "contain";
    return () => {
      document.documentElement.style.overscrollBehaviorX = "auto";
      document.body.style.overscrollBehaviorX = "auto";
    };
  }, []);

  // Create a new datastore for shapes to share data
  const datastore = BoardDatastore.useNewBoardDatastore();

  // Poll for image (access) tokens to make sure they don't expire
  useImageTokenPolling();

  // Set current application session context
  useEffect(() => {
    CommandContext.replaceSessionContext([{ workspaceId }, { workspaceBoardId: workspaceBoardId }]);
  }, [workspaceId, workspaceBoardId]);

  const boardConfig: CollaborativeBoardConfig = useMemo(() => {
    return {
      boardId: workspaceBoardId,
      editorUser: {
        accessToken: user.accessToken,
        name: user.name,
        color: getRandomColor(),
        userId: user.id,
      },
      // Only set `readOnly` to `false` if the user has a role that allows them to edit the board
      readOnly: isWorkspaceEditable !== true,
      runnerHttpUrl: Config.runnerHttpUrl,
      runnerWsUrl: Config.runnerWsUrl,
      workspaceId,
    };
  }, [isWorkspaceEditable, workspaceBoardId, user.accessToken, user.id, user.name, workspaceId]);

  // Check if we're loading
  if (loading || aclLoading) {
    return (
      <Box sx={{ display: "flex", flex: 1, m: 3, p: 3 }}>
        <Box sx={{ alignSelf: "center", textAlign: "center", flex: 1 }}>{t("Global.Status.Loading")}</Box>
      </Box>
    );
  }

  return (
    <BoardDatastoreContext.Provider value={datastore}>
      <WorkspaceBoardCommandManager app={tldrawEditor} workspaceBoardId={workspaceBoardId} />
      <WorkspaceBoardChatActionManager app={tldrawEditor} workspaceBoardId={workspaceBoardId} />
      {/* The div here is required and intentional. Otherwise, the tools get clipped on the right side. */}
      {workspaceBoardId !== "" && (
        <div className="tldraw">
          <CollaborativeBoard config={boardConfig} onMount={onMount} />
        </div>
      )}
    </BoardDatastoreContext.Provider>
  );

  function onMount(editor: Editor) {
    setTldrawEditor(editor);

    if (shapeIdFromUrl.length > 0) {
      // Try to find the shape by ID
      const shape = editor.getShape(shapeIdFromUrl as TLShapeId);
      if (shape) {
        // Select the shape
        editor.setSelectedShapes([shape]);

        // Use zoom level from URL when available, otherwise use default value
        const targetZoom = zFromUrl || 1;

        // Move camera to the shape
        const shapePageBounds = editor.getShapePageBounds(shape.id);
        if (shapePageBounds) {
          editor.zoomToBounds(shapePageBounds, { targetZoom });

          // Exit here to prevent the camera from being reset to the position in URL
          // or the user preferences
          return;
        }
      }
    }

    // Use camera position and zoom level from URL when available, fallback to user
    // preferences if available, otherwise use default values
    const zoomLevel = zFromUrl || workspaceBoardPreferences?.userPreference?.data.zoomLevel || 1;
    const cameraPosition = { x: xFromUrl, y: yFromUrl } ||
      workspaceBoardPreferences?.userPreference?.data.cameraPosition || { x: 0, y: 0 };

    // Set the camera to the user's last position
    editor.setCamera({
      x: cameraPosition.x,
      y: cameraPosition.y,
      z: zoomLevel,
    });
  }
}
