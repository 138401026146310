import { Permissions } from "@bigpi/permission";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import LabelIcon from "@mui/icons-material/Label";
import ShareIcon from "@mui/icons-material/Share";
import { Box, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip, Divider } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { WorkspaceAccessControlListDialog } from "Components/AccessControlList/WorkspaceAccessControlListDialog";
import { HasPermission } from "Components/HasPermission/HasPermission";
import { HasWorkspaceRole } from "Components/HasWorkspaceRole/HasWorkspaceRole";
import { ObjectRole, useReindexWorkspaceMutation } from "GraphQL/Generated/Apollo";
import { DeleteWorkspaceDialog } from "Pages/Workspaces/DeleteWorkspaceDialog";
import { RenameWorkspaceDialog } from "Pages/Workspaces/RenameWorkspaceDialog";
import { CopyWorkspaceDialog } from "./CopyWorkspaceDialog";
import { EditWorkspaceDescriptionDialog } from "./EditWorkspaceDescriptionDialog";
import { UpdateWorkspaceTagsDialog } from "./UpdateWorkspaceTagsDialog";

export interface IWorkspaceListMenu {
  workspaceId: string;
}

export function WorkspaceListMenu(props: IWorkspaceListMenu) {
  const { workspaceId } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [aclDialogOpen, setAclDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);
  const [copyWorkspaceDialogOpen, setCopyWorkspaceDialogOpen] = React.useState(false);
  const [editDescriptionDialogOpen, setEditDescriptionDialogOpen] = React.useState(false);
  const [updateWorkspaceTagsDialogOpen, setUpdateWorkspaceTagsDialogOpen] = React.useState(false);
  const [reindexEnabled, setReindexEnabled] = React.useState(true);
  const [ReindexWorkspace] = useReindexWorkspaceMutation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function reindexWorkspace() {
    // Disable the button to prevent multiple clicks
    setReindexEnabled(false);

    try {
      await ReindexWorkspace({
        variables: {
          input: {
            id: workspaceId,
          },
        },
      });
    } catch (error) {
      console.error(error);

      // Re-enable the button on failures, but not success
      setReindexEnabled(true);
    }
  }

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("Global.Action.Actions") || ""}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "workspace-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 24,
              height: 24,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => setAclDialogOpen(true)}>
          <ListItemIcon>
            <ShareIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Global.Action.Share")} />
        </MenuItem>

        <HasWorkspaceRole workspaceId={workspaceId} roles={[ObjectRole.Manager, ObjectRole.Owner]}>
          <MenuItem onClick={() => setRenameDialogOpen(true)}>
            <ListItemIcon>
              <DriveFileRenameOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.Rename")} />
          </MenuItem>

          <MenuItem onClick={() => setUpdateWorkspaceTagsDialogOpen(true)}>
            <ListItemIcon>
              <LabelIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.UpdateWorkspaceTags")} />
          </MenuItem>

          <MenuItem onClick={() => setCopyWorkspaceDialogOpen(true)}>
            <ListItemIcon>
              <ContentCopyOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.CopyWorkspace")} />
          </MenuItem>

          <MenuItem onClick={() => setEditDescriptionDialogOpen(true)}>
            <ListItemIcon>
              <DescriptionIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.EditDescription")} />
          </MenuItem>

          <Divider />
          <MenuItem onClick={() => setDeleteDialogOpen(true)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: "danger.main" }} primary={t("Global.Action.Delete")} />
          </MenuItem>
        </HasWorkspaceRole>
        <HasPermission permission={Permissions.PlatformDataWrite}>
          <Divider />
          <MenuItem disabled={!reindexEnabled} onClick={() => reindexWorkspace()}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.ReindexWorkspace")} />
          </MenuItem>
        </HasPermission>
      </Menu>
      {aclDialogOpen && (
        <WorkspaceAccessControlListDialog
          workspaceId={workspaceId}
          open={aclDialogOpen}
          onClose={() => setAclDialogOpen(false)}
        />
      )}
      {deleteDialogOpen && (
        <DeleteWorkspaceDialog workspaceId={workspaceId} open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} />
      )}
      {renameDialogOpen && (
        <RenameWorkspaceDialog workspaceId={workspaceId} open={renameDialogOpen} onClose={() => setRenameDialogOpen(false)} />
      )}
      {copyWorkspaceDialogOpen && (
        <CopyWorkspaceDialog
          workspaceId={workspaceId}
          open={copyWorkspaceDialogOpen}
          onClose={() => setCopyWorkspaceDialogOpen(false)}
        />
      )}
      {updateWorkspaceTagsDialogOpen && (
        <UpdateWorkspaceTagsDialog
          workspaceId={workspaceId}
          open={updateWorkspaceTagsDialogOpen}
          onClose={() => setUpdateWorkspaceTagsDialogOpen(false)}
        />
      )}
      {editDescriptionDialogOpen && (
        <EditWorkspaceDescriptionDialog
          workspaceId={workspaceId}
          open={editDescriptionDialogOpen}
          onClose={() => setEditDescriptionDialogOpen(false)}
        />
      )}
    </React.Fragment>
  );
}
