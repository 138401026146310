import { ContentTypes } from "@bigpi/cookbook";
import { Permissions } from "@bigpi/permission";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { HasPermission } from "Components/HasPermission/HasPermission";
import { SplitButton, SplitButtonProps } from "Components/SplitButton/SplitButton";
import { ObjectRole, WorkspaceFilesQuery } from "GraphQL/Generated/Apollo";
import { useHasWorkspaceRole } from "Hooks/useHasWorkspaceRole";
import { PublishToLibraryDialog } from "./PublishToLibraryDialog";

// *********************************************
// Types/Interfaces/Constants/Enums
// *********************************************/
export interface IWorkspaceFileMultiSelectActionButtonProps {
  selectedFiles: Array<WorkspaceFilesQuery["workspaceFiles"][number]>;
  workspaceId: string;
}

enum MultiSelectAction {
  Delete = "Delete",
  PublishToLibrary = "PublishToLibrary",
}

// *********************************************
// Component
// *********************************************/
export function WorkspaceFileMultiSelectActionButton(props: IWorkspaceFileMultiSelectActionButtonProps) {
  const { selectedFiles, workspaceId } = props;

  const { t } = useTranslation();
  const hasDeletePermissions = useHasWorkspaceRole(workspaceId, [ObjectRole.ContentManager, ObjectRole.Owner]);

  const [publishToLibraryDialogOpen, setPublishToLibraryDialogOpen] = useState(false);

  const onMultiSelectAction = useCallback(
    (action: string) => {
      switch (action as MultiSelectAction) {
        case MultiSelectAction.PublishToLibrary:
          setPublishToLibraryDialogOpen(true);
          break;
        case MultiSelectAction.Delete:
          // TODO: Delete selected files
          break;
        default:
          console.warn("Unknown action", action);
          break;
      }
    },
    [selectedFiles],
  );

  const multiSelectActions: SplitButtonProps["options"] = [
    {
      value: MultiSelectAction.PublishToLibrary,
      label: t("Global.Action.PublishToLibrary"),
    },
  ];
  if (hasDeletePermissions === true) {
    multiSelectActions.push({
      value: MultiSelectAction.Delete,
      label: t("Global.Action.Delete"),
    });
  }

  return (
    <HasPermission permission={Permissions.PatronLibraryWrite}>
      <SplitButton options={multiSelectActions} handleClick={onMultiSelectAction} />

      {publishToLibraryDialogOpen && (
        <PublishToLibraryDialog
          onClose={() => setPublishToLibraryDialogOpen(false)}
          open={publishToLibraryDialogOpen}
          selectedWorkspaceFiles={selectedFiles.map((file) => ({
            id: file.id,
            filename: file.filename,
            mimeType: file.mimeType as ContentTypes,
          }))}
          workspaceId={workspaceId}
        />
      )}
    </HasPermission>
  );
}
