import { Box, Card, Grid, CardHeader, ButtonBase, Typography, Checkbox, CardMedia, CardActions } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FileIcon } from "Components/FileIcon/FileIcon";
import { FilesQuery } from "GraphQL/Generated/Apollo";
import { formatFileCreatedAt } from "Utils/FileUtils";
import { LibraryListMenu } from "./LibraryListMenu";

interface ILibraryGridItemProps {
  checked: boolean;
  isSelectionEnabled: boolean;
  file: FilesQuery["files"][0];
  onClick: (id: string) => void;
  onSelectionChange: (id: string) => void;
  size: "small" | "medium" | "large";
}

export function LibraryGridItem(props: ILibraryGridItemProps) {
  const { checked, isSelectionEnabled, file, onClick, onSelectionChange, size } = props;
  const { t } = useTranslation();
  let actionComponent: JSX.Element | null = null;

  if (isSelectionEnabled) {
    actionComponent = <Checkbox checked={checked} onChange={() => onSelectionChange(file.id)} />;
  } else {
    actionComponent = <LibraryListMenu fileId={file.id} />;
  }

  return (
    <Grid
      item
      xl={size === "small" ? 3 : size === "medium" ? 4 : 6}
      lg={size === "small" ? 2 : size === "medium" ? 3 : 4}
      md={size === "small" ? 3 : size === "medium" ? 4 : 6}
      xs={size === "small" ? 6 : size === "medium" ? 6 : 12}
      sx={{ display: "grid" }}
    >
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderWidth: "2px",
          backgroundColor: "#e8eaeb",
          ":hover": {
            backgroundColor: "#d8dadb",
          },
        }}
      >
        <CardHeader
          avatar={<FileIcon mimetype={file.mimeType} />}
          title={
            <ButtonBase sx={{ textAlign: "start" }} disableRipple onClick={() => onClick(file.id)}>
              <Typography
                sx={{
                  textAlign: "start",
                  fontSize: size === "small" ? "1rem" : size === "medium" ? "1.1rem" : "1.2rem",
                  fontWeight: "500",
                  wordBreak: "break-word",
                }}
              >
                {file.name}
              </Typography>
            </ButtonBase>
          }
          action={actionComponent}
        />
        <CardActions sx={{ display: "block", bottom: 0 }}>
          <Box sx={{ mt: 1 }}>
            <Typography variant="caption" color="text.secondary">
              {formatFileCreatedAt(file.createdAt, t)}
            </Typography>
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
}
