import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { useBulkDeleteFileMutation, useFilenamesByIdsQuery } from "GraphQL/Generated/Apollo";

export interface IBulkDeleteFileDialogProps {
  onClose: () => void;
  open: boolean;
  fileIds: Array<string>;
}

export function BulkDeleteFileDialog(props: IBulkDeleteFileDialogProps) {
  const { open, fileIds } = props;
  const { t } = useTranslation();
  const [bulkDeleteFile] = useBulkDeleteFileMutation();
  const { data, loading, error } = useFilenamesByIdsQuery({ variables: { ids: fileIds } });
  const [filenames, setFilenames] = useState<Array<string>>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");

  function onClose() {
    setErrorMessage("");
    props.onClose();
  }

  async function onDelete(): Promise<void> {
    try {
      const result = await bulkDeleteFile({
        variables: {
          input: {
            ids: fileIds,
          },
        },
        refetchQueries: ["Files"],
      });

      if (result.data?.bulkDeleteFile.count === fileIds.length) {
        props.onClose();
      } else {
        setWarningMessage(t("Components.BulkDeleteFileDialog.PartialError"));
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(t("Components.BulkDeleteFileDialog.Error"));
    }
  }

  useEffect(() => {
    // Sort the filenames alphabetically
    if (data?.filesByIds) {
      setFilenames(
        data.filesByIds.map((file) => file.name).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" })),
      );
    }

    // Check if we have the same number of files as we have filenames
    if (data && data.filesByIds && data.filesByIds.length !== fileIds.length) {
      setWarningMessage(t("Components.BulkDeleteFileDialog.MismatchWarning"));
    }
  }, [data, fileIds, t]);

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
        <DialogTitle>{t("Components.BulkDeleteFileDialog.Title", { count: fileIds.length })}</DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {warningMessage && <Alert severity="warning">{warningMessage}</Alert>}
          <DialogContentText>{t("Components.BulkDeleteFileDialog.Tip")} </DialogContentText>
          <ul style={{ maxHeight: "300px", overflowY: "auto" }}>
            {filenames.map((filename, index) => (
              <li key={index}>{filename}</li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onDelete} disabled={loading} color="danger">
            {t("Global.Action.DeletePermanently")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
