import { ApolloError } from "@apollo/client";
import { Box, List, ListItem, ListItemText, ListItemIcon, Typography, Checkbox, ListItemButton } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FileIcon } from "Components/FileIcon/FileIcon";
import { FilesQuery } from "GraphQL/Generated/Apollo";
import { formatFileCreatedAt } from "Utils/FileUtils";
import { LibraryListMenu } from "./LibraryListMenu";

interface ILibraryListProps {
  isSelectionEnabled: boolean;
  loading: boolean;
  rows: FilesQuery["files"];
  error?: ApolloError;
  selectedIds: Array<string>;
  onSelectionChange: (id: string) => void;
  onClick: (id: string) => void;
}

export function LibraryList(props: ILibraryListProps) {
  const { error, isSelectionEnabled, loading, rows, onClick, selectedIds, onSelectionChange } = props;
  const { t } = useTranslation();

  return (
    <>
      {loading && <Typography variant="caption">{t("Global.Status.Loading")}</Typography>}
      {!loading && rows.length > 0 && (
        <List
          component="nav"
          aria-label={t("Pages.Library.List.AvailableFiles")}
          sx={{ maxWidth: "960px", display: "flex", flexDirection: "column", flex: 1 }}
        >
          {rows.map((row) => {
            return (
              <ListItem key={row.id} secondaryAction={<LibraryListMenu fileId={row.id} />} disablePadding>
                {isSelectionEnabled && (
                  <ListItemIcon>
                    <Checkbox checked={selectedIds.includes(row.id)} onChange={() => onSelectionChange(row.id)} />
                  </ListItemIcon>
                )}

                <ListItemButton sx={{ cursor: "default" }} disableRipple onClick={() => onClick(row.id)}>
                  <ListItemIcon>
                    <FileIcon mimetype={row.mimeType} />
                  </ListItemIcon>

                  <ListItemText
                    primary={row.name}
                    primaryTypographyProps={{ fontWeight: "500" }}
                    secondary={
                      <Box sx={{ display: "flex", flexDirection: "column" }} component="span">
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} component="span">
                          {formatFileCreatedAt(row.createdAt, t)}
                        </Box>
                      </Box>
                    }
                    sx={{ cursor: "pointer" }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
      {!loading && !error && rows.length === 0 && (
        <Typography variant="caption" color="textSecondary">
          {t("Pages.Library.List.NoItems")}
        </Typography>
      )}
      {error && (
        <Typography variant="caption" color="error.main">
          {t("Pages.Library.List.LoadError")}
        </Typography>
      )}
    </>
  );
}
