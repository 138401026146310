import { ContentTypes, FileSourceType, FILE_UPLOAD_MAX_FILE_SIZE, ONE_MB } from "@bigpi/cookbook";
import { Box, FormControl, InputLabel, OutlinedInput, InputAdornment, Typography, IconButton } from "@mui/material";
import { SearchOutlined, ViewListOutlined, GridViewOutlined, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { useDropzone, Accept, FileRejection, ErrorCode as ReactDropzoneErrorCode } from "react-dropzone";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { v4 as uuidV4 } from "uuid";

import { CommandContext } from "CommandContext";
import { FilePreviewDialog } from "Components/FilePreviewDialog/FilePreviewDialog";
import { IFileWithId } from "Components/Upload/FilesList";
import { useUpsertLibraryPageUserPreferencesMutation, useLibraryPageUserPreferencesQuery } from "GraphQL/Generated/Apollo";
import { CONTENT_LIBRARY_PAGE_USER_PREFERENCES_KEY } from "GraphQL/UserPreference";
import { LibraryGridSize } from "Pages/Library/LibraryGrid";
import { LibraryView } from "Pages/Library/LibraryView";
import { FileMultiSelectActionButton } from "./FileMultiSelectActionButton";
import { LibraryUploadDialog } from "./LibraryUploadDialog";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
type LibraryViewType = "list" | "grid";

interface ILibraryPageUserPreferences {
  gridSize: LibraryGridSize;
  viewType: LibraryViewType;
}

// TODO: This needs to move to central location to be shared with workspaces
const FILE_UPLOAD_ACCEPTED_MIMETYPE: Accept = {
  [ContentTypes.PDF]: [".pdf"],
  [ContentTypes.DOC]: [".doc"],
  [ContentTypes.DOCX]: [".docx"],
  [ContentTypes.RTF]: [".rtf"],
  [ContentTypes.RTF_TEXT]: [".rtf"],
  [ContentTypes.RTF_X]: [".rtf"],
  [ContentTypes.RTF_RICHTEXT]: [".rtf"],
  [ContentTypes.PLAIN_TEXT]: [".txt"],
  [ContentTypes.HTML]: [".html"],
};

const FILE_UPLOAD_MAX_FILES = 50;

// *********************************************
// Component
// *********************************************/
export function LibraryPage() {
  const { t } = useTranslation();

  // State
  const [isSelectionEnabled, setIsSelectionEnabled] = useState(false);
  const [preferences, setPreferences] = useState<ILibraryPageUserPreferences>({
    gridSize: "medium",
    viewType: "list",
  });
  const [searchValue, setSearchValue] = useState("");
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const [previewId, setPreviewId] = useState<string | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<Array<IFileWithId>>([]);
  const [isDroppingFiles, setIsDroppingFiles] = useState(false);

  // Files query
  const { data: persistedPreferences } = useLibraryPageUserPreferencesQuery({
    variables: {
      key: CONTENT_LIBRARY_PAGE_USER_PREFERENCES_KEY,
    },
  });

  useEffect(() => {
    // Set current application session context
    CommandContext.replaceSessionContext([]);
  }, []);

  // User preferences update mutation
  const [upsertUserPreference] = useUpsertLibraryPageUserPreferencesMutation({
    // Update cache directly since the server will not return the fully cascaded data, just the user portion
    // This ensures any default and org-level preferences are not lost
    update: (cache, data) => {
      cache.modify({
        fields: {
          userPreference(existing = {}) {
            return {
              ...existing,
              data: {
                ...existing.data,
                ...data.data?.upsertUserPreference.data,
              },
            };
          },
        },
      });
    },
  });

  // Hooks
  const updatePreferences = useCallback(
    (preferences: Partial<ILibraryPageUserPreferences>) => {
      setPreferences((current) => {
        return { ...current, ...preferences };
      });
      setSelectedIds([]);
      upsertUserPreference({
        variables: {
          input: {
            key: CONTENT_LIBRARY_PAGE_USER_PREFERENCES_KEY,
            data: preferences,
          },
        },
      });
    },
    [upsertUserPreference],
  );

  useEffect(() => {
    // The preferences have changed, update the state
    setPreferences({
      gridSize: persistedPreferences?.userPreference?.data?.gridSize || "medium",
      viewType: persistedPreferences?.userPreference?.data?.viewType || "grid",
    });
  }, [persistedPreferences]);

  const onSelectionChange = useCallback((id: string) => {
    setSelectedIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((i) => i !== id);
      } else {
        return [...prev, id];
      }
    });
  }, []);

  // Dropzone
  const { getRootProps, getInputProps } = useDropzone({
    // Allow click to open file dialog
    noClick: true,
    noKeyboard: true,

    // Accepted file types
    accept: FILE_UPLOAD_ACCEPTED_MIMETYPE,

    // Maximum file size allowed
    maxSize: FILE_UPLOAD_MAX_FILE_SIZE,

    // Maximum number of files allowed
    maxFiles: FILE_UPLOAD_MAX_FILES,

    // Called when files are dropped/selected
    onDrop: (acceptedFiles: Array<File>) => {
      setSelectedFiles((prevState) => {
        const newFiles = acceptedFiles.map((file) => {
          return {
            id: uuidV4(),
            file,
          };
        });
        return [...prevState, ...newFiles];
      });
      setIsDroppingFiles(false);
    },

    onDragEnter: () => {
      setIsDroppingFiles(true);
    },
    onDragLeave: () => {
      setIsDroppingFiles(false);
    },

    // Called when files are rejected
    onDropRejected: (fileRejections: Array<FileRejection>) => {
      const messages: Set<string> = new Set();
      fileRejections.forEach((fileRejection) => {
        fileRejection.errors.forEach((error) => {
          switch (error.code) {
            case ReactDropzoneErrorCode.FileInvalidType:
              messages.add(t("Components.LibraryUploadDialog.Errors.FileInvalidType"));
              break;
            case ReactDropzoneErrorCode.FileTooLarge:
              messages.add(
                t("Components.LibraryUploadDialog.Errors.FileTooLarge", {
                  maxInMBs: 100 / ONE_MB,
                }),
              );
              break;
            default:
              messages.add(
                t("Components.LibraryUploadDialog.Errors.TooManyFiles", {
                  maxFiles: FILE_UPLOAD_MAX_FILES,
                }),
              );
              break;
          }
        });
      });

      toast.error(Array.from(messages).join("; "));
    },

    // Allow multiple files to be uploaded
    multiple: true,
  });

  const { ref, ...rootProps } = getRootProps();

  return (
    <>
      <Helmet>
        <title>{t("Pages.Library.Title")}</title>
      </Helmet>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 3,
          mb: 0,
          alignSelf: "center",
          width: "90%",
          maxWidth: preferences.viewType === "list" ? "960px" : "1920px",
          minWidth: "400px",
        }}
      >
        <Typography variant="h5">{t("Pages.Library.HeadingLabel")}</Typography>
      </Box>

      <Box
        sx={{
          alignSelf: "center",
          backgroundColor: isDroppingFiles ? "#e6efff" : "transparent",
          border: `1px solid #f1f1f1`,
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          m: 3,
          maxWidth: preferences.viewType === "list" ? "960px" : "1920px",
          mb: 10,
          minWidth: "400px",
          width: "90%",
        }}
        {...rootProps}
        ref={ref}
      >
        <Box sx={{ display: "flex", p: 3, pb: 0, justifyContent: "center" }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="find-workspace-input">{t("Pages.Library.FindLabel")}</InputLabel>
            <OutlinedInput
              id="find-workspace-input"
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              }
              label={t("Pages.Library.FindLabel")}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", p: 3, pb: 0, justifyContent: "space-between", flexWrap: "wrap" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isSelectionEnabled && <FileMultiSelectActionButton selectedIds={selectedIds} />}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <IconButton size="medium" onClick={() => setIsSelectionEnabled(!isSelectionEnabled)}>
              {isSelectionEnabled ? <CheckBox /> : <CheckBoxOutlineBlank />}
            </IconButton>

            <IconButton
              color={preferences.viewType === "list" ? "primary" : "default"}
              size="medium"
              onClick={() => updatePreferences({ viewType: "list" })}
            >
              <ViewListOutlined />
            </IconButton>

            <IconButton
              color={preferences.viewType === "grid" ? "primary" : "default"}
              size="medium"
              onClick={() => updatePreferences({ viewType: "grid" })}
            >
              <GridViewOutlined />
            </IconButton>
          </Box>
        </Box>

        <LibraryView
          isSelectionEnabled={isSelectionEnabled}
          onClick={setPreviewId}
          gridSize={preferences.gridSize}
          viewType={preferences.viewType}
          onSelectionChange={onSelectionChange}
          selectedIds={selectedIds}
          searchValue={searchValue}
          onGridSizeChange={(gridSize) => updatePreferences({ gridSize })}
        />

        {previewId && (
          <FilePreviewDialog fileId={previewId} fileType={FileSourceType.Library} onClose={() => setPreviewId(null)} />
        )}
      </Box>
      {selectedFiles.length > 0 && (
        <Box
          {...rootProps}
          ref={ref}
          sx={{
            backgroundColor: isDroppingFiles ? "#e6efff" : "transparent",
          }}
        >
          <LibraryUploadDialog
            onClose={() => setSelectedFiles([])}
            selectedFiles={selectedFiles}
            onRemoveFile={(id) => setSelectedFiles(selectedFiles.filter((file) => file.id !== id))}
          />
        </Box>
      )}
    </>
  );
}
