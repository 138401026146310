import { Box, FormControl, InputLabel, OutlinedInput, InputAdornment, Typography, IconButton } from "@mui/material";
import { SearchOutlined, ViewListOutlined, GridViewOutlined, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { CommandContext } from "CommandContext";
import { DocumentPreviewDialog } from "Components/DocumentPreviewDialog/DocumentPreviewDialog";
import { useUpsertDocumentsPageUserPreferencesMutation, useDocumentsPageUserPreferencesQuery } from "GraphQL/Generated/Apollo";
import { DOCUMENTS_PAGE_USER_PREFERENCES_KEY } from "GraphQL/UserPreference";
import { DocumentsGridSize } from "Pages/Documents/DocumentsGrid";
import { DocumentsView } from "Pages/Documents/DocumentsView";
import { DocumentMultiSelectActionButton } from "./DocumentMultiSelectActionButton";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
type DocumentsViewType = "list" | "grid";

interface IDocumentsPageUserPreferences {
  gridSize: DocumentsGridSize;
  viewType: DocumentsViewType;
}

// *********************************************
// Component
// *********************************************/
export function DocumentsPage() {
  const { t } = useTranslation();

  // State
  const [isSelectionEnabled, setIsSelectionEnabled] = useState(false);
  const [preferences, setPreferences] = useState<IDocumentsPageUserPreferences>({
    gridSize: "medium",
    viewType: "list",
  });
  const [searchValue, setSearchValue] = useState("");
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const [previewId, setPreviewId] = useState<string | null>(null);

  // Files query
  const { data: persistedPreferences } = useDocumentsPageUserPreferencesQuery({
    variables: {
      key: DOCUMENTS_PAGE_USER_PREFERENCES_KEY,
    },
  });

  useEffect(() => {
    // Set current application session context
    CommandContext.replaceSessionContext([]);
  }, []);

  // User preferences update mutation
  const [upsertUserPreference] = useUpsertDocumentsPageUserPreferencesMutation({
    // Update cache directly since the server will not return the fully cascaded data, just the user portion
    // This ensures any default and org-level preferences are not lost
    update: (cache, data) => {
      cache.modify({
        fields: {
          userPreference(existing = {}) {
            return {
              ...existing,
              data: {
                ...existing.data,
                ...data.data?.upsertUserPreference.data,
              },
            };
          },
        },
      });
    },
  });

  // Hooks
  const updatePreferences = useCallback(
    (preferences: Partial<IDocumentsPageUserPreferences>) => {
      setPreferences((current) => {
        return { ...current, ...preferences };
      });
      setSelectedIds([]);
      upsertUserPreference({
        variables: {
          input: {
            key: DOCUMENTS_PAGE_USER_PREFERENCES_KEY,
            data: preferences,
          },
        },
      });
    },
    [upsertUserPreference],
  );

  useEffect(() => {
    // The preferences have changed, update the state
    setPreferences({
      gridSize: persistedPreferences?.userPreference?.data?.gridSize || "medium",
      viewType: persistedPreferences?.userPreference?.data?.viewType || "grid",
    });
  }, [persistedPreferences]);

  const onSelectionChange = useCallback((id: string) => {
    setSelectedIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((i) => i !== id);
      } else {
        return [...prev, id];
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("Pages.Documents.Title")}</title>
      </Helmet>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 3,
          mb: 0,
          alignSelf: "center",
          width: "90%",
          maxWidth: preferences.viewType === "list" ? "960px" : "1920px",
          minWidth: "400px",
        }}
      >
        <Typography variant="h5">{t("Pages.Documents.HeadingLabel")}</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          m: 3,
          alignSelf: "center",
          flexDirection: "column",
          width: "90%",
          maxWidth: preferences.viewType === "list" ? "960px" : "1920px",
          minWidth: "400px",
          border: `1px solid #f1f1f1`,
          borderRadius: "16px",
          mb: 10,
        }}
      >
        <Box sx={{ display: "flex", p: 3, pb: 0, justifyContent: "center" }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="find-workspace-input">{t("Pages.Documents.FindLabel")}</InputLabel>
            <OutlinedInput
              id="find-workspace-input"
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              }
              label={t("Pages.Documents.FindLabel")}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", p: 3, pb: 0, justifyContent: "space-between", flexWrap: "wrap" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isSelectionEnabled && <DocumentMultiSelectActionButton selectedIds={selectedIds} />}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <IconButton size="medium" onClick={() => setIsSelectionEnabled(!isSelectionEnabled)}>
              {isSelectionEnabled ? <CheckBox /> : <CheckBoxOutlineBlank />}
            </IconButton>

            <IconButton
              color={preferences.viewType === "list" ? "primary" : "default"}
              size="medium"
              onClick={() => updatePreferences({ viewType: "list" })}
            >
              <ViewListOutlined />
            </IconButton>

            <IconButton
              color={preferences.viewType === "grid" ? "primary" : "default"}
              size="medium"
              onClick={() => updatePreferences({ viewType: "grid" })}
            >
              <GridViewOutlined />
            </IconButton>
          </Box>
        </Box>

        <DocumentsView
          gridSize={preferences.gridSize}
          isSelectionEnabled={isSelectionEnabled}
          viewType={preferences.viewType}
          onSelectionChange={onSelectionChange}
          selectedIds={selectedIds}
          searchValue={searchValue}
          onGridSizeChange={(gridSize) => updatePreferences({ gridSize })}
          onClick={setPreviewId}
        />

        {previewId && <DocumentPreviewDialog docId={previewId} onClose={() => setPreviewId(null)} />}
      </Box>
    </>
  );
}
