import { Box, Button } from "@mui/material";
import { useValue } from "@tldraw/tldraw";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DocumentsList } from "./DocumentsList";
import { DocumentsGrid, DocumentsGridSize } from "./DocumentsGrid";
import { useDocumentsQuery } from "GraphQL/Generated/Apollo";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
interface IDocumentsViewProps {
  gridSize: DocumentsGridSize;
  isSelectionEnabled: boolean;
  onClick: (id: string) => void;
  onGridSizeChange: (gridSize: DocumentsGridSize) => void;
  onSelectionChange: (id: string) => void;
  searchValue?: string;
  selectedIds: Array<string>;
  viewType: "list" | "grid";
}

const DEFAULT_PAGE_SIZE = 10;

// *********************************************
// Component
// *********************************************/
export function DocumentsView(props: IDocumentsViewProps) {
  const { t } = useTranslation();
  const { gridSize, isSelectionEnabled, viewType, selectedIds, onClick, onSelectionChange, searchValue, onGridSizeChange } =
    props;

  // State
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<Record<string, string>>({ updatedAt: "desc" });

  // Documents query
  const {
    data: documentsData,
    fetchMore: fetchMoreDocuments,
    loading,
    error,
  } = useDocumentsQuery({
    variables: {
      limit: DEFAULT_PAGE_SIZE,
      offset,
      orderBy,
      filters: {
        searchTerm: searchValue,
      },
    },
  });

  // Hooks
  const rows = useValue(
    "documentsRows",
    () => {
      return documentsData?.documents || [];
    },
    [documentsData],
  );

  const count = useValue(
    "documentsCount",
    () => {
      return documentsData?.documentAggregate.count || 0;
    },
    [documentsData],
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Box sx={{ display: "flex", p: 3, justifyContent: "center" }}>
        {viewType === "list" ? (
          <DocumentsList
            error={error}
            isSelectionEnabled={isSelectionEnabled}
            loading={loading}
            onClick={onClick}
            onSelectionChange={onSelectionChange}
            rows={rows}
            selectedIds={selectedIds}
          />
        ) : (
          <DocumentsGrid
            error={error}
            gridSize={gridSize}
            isSelectionEnabled={isSelectionEnabled}
            loading={loading}
            onClick={onClick}
            onGridSizeChange={onGridSizeChange}
            onSelectionChange={onSelectionChange}
            rows={rows}
            selectedIds={selectedIds}
          />
        )}
      </Box>

      {count > rows.length && (
        <Button
          sx={{ alignSelf: "center", maxWidth: "150px" }}
          onClick={() => {
            fetchMoreDocuments({
              variables: {
                offset: rows.length,
                filters: {
                  searchTerm: searchValue,
                },
              },
            });
          }}
        >
          {t("Pages.Documents.List.ShowMore")}
        </Button>
      )}
    </Box>
  );
}
