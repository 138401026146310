import { useReactiveVar } from "@apollo/client";
import { ICommandContext } from "@bigpi/cookbook";
import { Box, Typography, Tooltip } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import { ChatMessageList } from "Chat/ChatMessageList/ChatMessageList";
import { ChatMessageEditor } from "Chat/ChatMessageEditor/ChatMessageEditor";
import { CommandContext } from "CommandContext";
import { APP_SIDEBAR_ICON_WIDTH } from "Components/AppRightSidebar/AppRightSidebar";
import { useCommandExecutor } from "Components/CommandManagers/useCommandExecutor";
import { ChatMessageChannelType, useCreateChatMessageMutation, useWorkspaceQuery } from "GraphQL/Generated/Apollo";
import { RightSidebarTitle } from "Hooks/useAppRightSidebarComponents";

// *********************************************
// Type/Interface
// *********************************************
export interface ChatPaneProps {
  channelId: string;
  channelType: ChatMessageChannelType;
}

// *********************************************
// Component
// *********************************************
export function ChatPane(props: ChatPaneProps) {
  const { channelId, channelType } = props;
  const [parentId, setParentId] = useState<string | null>(null);
  const [isParentPrivate, setIsParentPrivate] = useState<boolean>(false);
  const [isSendEnabled, setIsSendEnabled] = useState(true);
  const commandContext = useReactiveVar<ICommandContext>(CommandContext.getCommandContext);
  const commandContextRef = useRef<ICommandContext>(commandContext);
  const chatPaneRef = useRef<HTMLDivElement>(null);
  const commandExecutor = useCommandExecutor();

  const { data: workspaceData } = useWorkspaceQuery({
    variables: { id: channelId },
    skip: channelType !== ChatMessageChannelType.Workspace,
  });

  // Get the hook to send a message
  const [createChatMessage] = useCreateChatMessageMutation();

  const sendMessage = useCallback(
    (currentMessage: string) => {
      if (!isSendEnabled) {
        return;
      }

      setIsSendEnabled(false);
      const message = currentMessage.trim();

      createChatMessage({
        variables: {
          input: {
            channelType,
            channelId,
            commandContext: {
              ...commandContextRef.current,
              user: {
                ...commandContextRef.current.user,
                commandSessionId: commandExecutor.sessionId,
              },
            },
            message: message,
            parentId,
          },
        },
        onCompleted: (data) => {
          // TODO: We need to have some some of buffer/status in place in place instead,
          // so that you can type real fast and not have to wait for the server to respond
          setIsSendEnabled(true);
        },
        onError: (error) => {
          console.error("Failed to send message", error);
          setIsSendEnabled(true);
        },
      });
    },
    [createChatMessage, channelId, parentId, channelType, isSendEnabled],
  );

  useEffect(() => {
    commandContextRef.current = { ...CommandContext.getCommandContext() };
  }, [commandContext]);

  useEffect(() => {
    const handleCopy = (event: ClipboardEvent) => {
      const target = event.target as HTMLElement;
      if (chatPaneRef.current?.contains(target)) {
        event.stopImmediatePropagation();
      }
    };
    if (chatPaneRef.current) {
      chatPaneRef.current.addEventListener("copy", handleCopy);
    }

    return () => {
      if (chatPaneRef.current) {
        chatPaneRef.current.removeEventListener("copy", handleCopy);
      }
    };
  }, [chatPaneRef]);

  const onMessageParentSet = useCallback((parentId: string | null, isPrivate?: boolean) => {
    setParentId(parentId);
    setIsParentPrivate(!!isPrivate);
  }, []);

  return (
    <Box
      sx={{ display: "flex", flex: 1, flexDirection: "column", width: "100%", minHeight: 0 }}
      className="chat"
      ref={chatPaneRef}
    >
      {workspaceData?.workspace && (
        <RightSidebarTitle sx={{ backgroundColor: isParentPrivate ? "var(--bistro-private-mention-color)" : "" }}>
          <Typography
            noWrap={true}
            component="div"
            variant="subtitle2"
            sx={{ maxWidth: `calc(100% - ${APP_SIDEBAR_ICON_WIDTH * 2}px)`, height: "40px", pl: 2, pt: 1 }}
          >
            <Tooltip title={workspaceData.workspace.name}>
              <span>#{workspaceData.workspace.name}</span>
            </Tooltip>
          </Typography>
        </RightSidebarTitle>
      )}
      <ChatMessageList channelId={channelId} parentId={parentId} onMessageParentSet={onMessageParentSet} />
      <ChatMessageEditor isSendEnabled={isSendEnabled} onSend={sendMessage} channelId={channelId} channelType={channelType} />
    </Box>
  );
}
