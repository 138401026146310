import { DocumentType } from "@bigpi/cookbook";
import { Editor, IEditorConfig, ToolbarButton, ToolbarItemBase } from "@bigpi/editor-tiptap";
import { EditNoteOutlined } from "@mui/icons-material";
import { Box, Button, FormControl, InputLabel, MenuItem, Popover, Select, Stack, TextField, Typography } from "@mui/material";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";

const ITEM_NAME = "editShapeMeta";

/**
 * A toolbar button that allows the user to edit the metadata of a shape.
 */
export class EditShapeMetaButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  isExternallyTranslated: boolean = true;

  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions, extensionOptions } = config;
    const shapeMeta = extensionOptions?.EditShapeMeta?.getShapeMeta?.() ?? {};

    return (
      <EditShapeMetaButton
        key={ITEM_NAME}
        toolbarOptions={toolbarOptions}
        shapeMeta={shapeMeta}
        setShapeMeta={extensionOptions?.EditShapeMeta?.setShapeMeta ?? (() => {})}
      />
    );
  }
}

export interface IShapeMeta {
  name?: string;
  documentType?: DocumentType;
}

/**
 * Props for EditShapeMetaButton.
 */
export interface EditShapeMetaButtonProps {
  setShapeMeta: (shapeMeta: IShapeMeta) => void;
  shapeMeta: IShapeMeta;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the `editShapeMeta` command.
 */
export const EditShapeMetaButton = (props: EditShapeMetaButtonProps) => {
  const {
    setShapeMeta,
    shapeMeta: { name, documentType },
    toolbarOptions,
  } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [nameValue, setNameValue] = useState<string>(name ?? "");
  const [documentTypeValue, setDocumentTypeValue] = useState<DocumentType>(documentType ?? DocumentType.None);

  const openLinkPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    setNameValue(name ?? "");
    setDocumentTypeValue(documentType ?? DocumentType.None);
  };

  const closeLinkPopover = () => {
    setAnchorEl(null);
  };

  const resetState = () => {
    setNameValue(name ?? "");
    setDocumentTypeValue(documentType ?? DocumentType.None);

    closeLinkPopover();
  };

  const onSave = () => {
    setShapeMeta({
      name: nameValue,
      documentType: documentTypeValue,
    });

    resetState();
  };

  const onCancel = () => {
    resetState();
  };

  const open = Boolean(anchorEl);
  const id = open ? "edit-shape-meta-button" : undefined;
  const canSave = nameValue !== name || documentTypeValue !== documentType;

  return (
    <>
      <Box component="span">
        <ToolbarButton
          Icon={EditNoteOutlined}
          tooltip={t(`Editor.EditShapeMeta.Tooltip`)}
          onClick={(e) => (open ? closeLinkPopover() : openLinkPopover(e))}
          tooltipPlacement={tooltipPlacement}
        />
      </Box>

      <Box component="span">
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={closeLinkPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Stack
            component="form"
            sx={{
              m: 1,
              width: "40ch",
              padding: "1ch",
            }}
            spacing={1}
            noValidate
            autoComplete="off"
          >
            <Typography variant="h6">{t(`Editor.EditShapeMeta.Dialog.Title`)}</Typography>

            <TextField
              autoFocus
              id="edit-name-field"
              label={t(`Editor.EditShapeMeta.Dialog.NameLabel`)}
              variant="standard"
              size="small"
              value={nameValue}
              onChange={(e) => {
                setNameValue(e.target.value);
              }}
              style={{ marginTop: "16px" }}
            />

            <FormControl style={{ marginTop: "16px" }} fullWidth>
              <InputLabel id="edit-document-type-select-label">{t(`Editor.EditShapeMeta.Dialog.DocumentTypeLabel`)}</InputLabel>
              <Select
                labelId="edit-document-type-label"
                id="edit-document-type-select"
                value={documentTypeValue}
                label={t(`Editor.EditShapeMeta.Dialog.DocumentTypeLabel`)}
                onChange={(e) => {
                  setDocumentTypeValue(e.target.value as DocumentType);
                }}
              >
                <MenuItem value="None">{t(`Editor.EditShapeMeta.Dialog.DocumentType.None`)}</MenuItem>
                <MenuItem value={DocumentType.KnowledgeBaseArticle}>
                  {t(`Editor.EditShapeMeta.Dialog.DocumentType.KnowledgeBaseArticle`)}
                </MenuItem>
              </Select>
            </FormControl>

            <Box component="span" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="text" onClick={onSave} disabled={!canSave}>
                {t(`Global.Action.Save`)}
              </Button>

              <Button variant="text" onClick={onCancel} style={{ marginLeft: "10px" }}>
                {t(`Global.Action.Cancel`)}
              </Button>
            </Box>
          </Stack>
        </Popover>
      </Box>
    </>
  );
};
