import { useApolloClient } from "@apollo/client";
import { Constants } from "@bigpi/permission";
import { useAuthUser } from "@frontegg/react";
import { Box, InputLabel, List } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { ThreadMessage } from "./ThreadMessage";
import { ChatMessage } from "Chat/ChatMessage/ChatMessage";
import { getChatMessageToolbarActions } from "Chat/Utils/ChatMessageToolbarActions";
import { CHAT_MESSAGES_ADDED_SUBSCRIPTION, CHAT_MESSAGES_DELETED_SUBSCRIPTION } from "GraphQL/Chat/Subscription";
import {
  ChatMessageChannelType,
  OnChatMessagesAddedSubscription,
  OnChatMessagesAddedSubscriptionVariables,
  OnChatMessagesDeletedSubscription,
  OnChatMessagesDeletedSubscriptionVariables,
  useChatMessagesQuery,
  useOnChatMessagesUpdatedSubscription,
  useUserLazyQuery,
} from "GraphQL/Generated/Apollo";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
export interface ChatMessageListProps {
  channelId: string;
  parentId: string | null;
  onMessageParentSet: (parentId: string | null, isPrivate?: boolean) => void;
}

const TIME_DIFFERENCE_THRESHOLD = 30 * 1000; // 30 seconds

// *********************************************
// Component
// *********************************************/
export function ChatMessageList(props: ChatMessageListProps) {
  const { channelId, onMessageParentSet, parentId } = props;
  const { t } = useTranslation();
  const messagesRef = useRef<HTMLUListElement>(null);
  const user = useAuthUser();

  const { data, loading, error, subscribeToMore, refetch } = useChatMessagesQuery({
    variables: {
      channelType: ChatMessageChannelType.Workspace,
      channelId,
      parentId,
    },
  });

  const { data: updateData } = useOnChatMessagesUpdatedSubscription({
    variables: {
      channelId,
      channelType: ChatMessageChannelType.Workspace,
      parentId,
    },
  });

  const [getUser] = useUserLazyQuery();
  const apolloClient = useApolloClient();

  // Subscribe to updates for this channel or thread
  useEffect(() => {
    const unsubscribeCreated = subscribeToMore<OnChatMessagesAddedSubscription, OnChatMessagesAddedSubscriptionVariables>({
      document: CHAT_MESSAGES_ADDED_SUBSCRIPTION,
      variables: {
        channelType: ChatMessageChannelType.Workspace,
        channelId,
        parentId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.onChatMessageAdded) {
          return prev;
        }
        const newItem = subscriptionData.data.onChatMessageAdded;

        if (newItem.createdBy) {
          getUser({ variables: { id: newItem.createdBy } }).then((userResponse) => {
            const createdByUser = userResponse.data?.user;
            // Open thread (set parentId) when new message from Human has AI user mention
            const currentTimeInMs = new Date().getTime();
            const createdTimeInMs = new Date(newItem.createdAt).getTime();
            const timeDiffInMs = currentTimeInMs - createdTimeInMs;
            const mentionsTargetIds = newItem.mentions?.map((mention) => mention.targetId) || [];
            const privateRecipientIds = newItem.privateRecipientIds || [];

            // Switches to thread view when the AI user is mentioned
            if (
              !parentId &&
              createdByUser?.id === user.id &&
              mentionsTargetIds.includes(Constants.AI_USER_ID) &&
              newItem.parentId === null &&
              timeDiffInMs < TIME_DIFFERENCE_THRESHOLD
            ) {
              onMessageParentSet(newItem.id);
            }

            // Switches to thread view when current user created private message
            if (
              !parentId &&
              createdByUser?.id === user.id &&
              privateRecipientIds.includes(user.id) &&
              newItem.parentId === null &&
              timeDiffInMs < TIME_DIFFERENCE_THRESHOLD
            ) {
              onMessageParentSet(newItem.id, true);
            }

            const latestItem = {
              ...newItem,
              createdByUser,
            };

            apolloClient.cache.modify({
              fields: {
                chatMessages() {
                  return [...prev.chatMessages, latestItem];
                },
              },
            });
          });
        }
        return Object.assign({}, prev, {
          chatMessages: [
            ...prev.chatMessages,
            {
              ...newItem,
              createdByUser: {
                id: newItem.createdBy,
                name: "",
                profilePictureUrl: "",
              },
            },
          ],
        });
      },
    });

    const unsubscribeDeleted = subscribeToMore<OnChatMessagesDeletedSubscription, OnChatMessagesDeletedSubscriptionVariables>({
      document: CHAT_MESSAGES_DELETED_SUBSCRIPTION,
      variables: {
        channelType: ChatMessageChannelType.Workspace,
        channelId,
        parentId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.onChatMessageDeleted) {
          return prev;
        }

        const data = subscriptionData.data.onChatMessageDeleted;

        if (parentId === data.id) {
          onMessageParentSet(null);
        }

        // Remove the deleted message from the list
        return Object.assign({}, prev, {
          chatMessages: prev.chatMessages.filter((message) => message.id !== data.id),
        });
      },
    });

    return () => {
      unsubscribeCreated();
      unsubscribeDeleted();
    };
  }, [subscribeToMore, channelId, parentId, user.id, getUser, apolloClient]);

  useEffect(() => {
    if (messagesRef.current) {
      // TODO: Check if the user has scrolled away from the newest messages before scrolling
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [data]);

  const onCloseThread = useCallback(() => {
    onMessageParentSet(null);
  }, []);

  if (error) {
    return <Box sx={{ display: "flex", flex: 1 }}>{t("Components.Chat.MessageList.Error", { errorMessage: error.message })}</Box>;
  }

  if (data?.chatMessages?.length === 0 && !parentId) {
    return (
      <Box sx={{ display: "flex", flex: 1 }}>
        <InputLabel sx={{ p: 2, textWrap: "pretty" }}>{t("Components.Chat.MessageList.AiSearchInstructions")}</InputLabel>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", minHeight: 0, flexDirection: "column", flex: 1, overflowY: "scroll" }} ref={messagesRef}>
      {parentId && (
        <ThreadMessage
          channelId={channelId}
          parentId={parentId}
          onClose={onCloseThread}
          channelType={ChatMessageChannelType.Workspace}
        />
      )}
      <List sx={{ display: "flex", flexDirection: "column" }}>
        {data?.chatMessages.map((message) => {
          const chatMessageToolbarActions = getChatMessageToolbarActions(message, user.id, "list");
          return (
            <ChatMessage
              channelId={channelId}
              channelType={ChatMessageChannelType.Workspace}
              key={message.id}
              message={message}
              onMessageParentSet={onMessageParentSet}
              toolbarActions={chatMessageToolbarActions}
            />
          );
        })}
      </List>
    </Box>
  );
}
