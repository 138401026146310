import { hasRolePermission, Permissions } from "@bigpi/permission";
import { useAuthUser } from "@frontegg/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { BulkDeleteFileDialog } from "Components/BulkDeleteFileDialog/BulkDeleteFileDialog";
import { OPTION_VALUE_DIVIDER, SplitButton, SplitButtonProps } from "Components/SplitButton/SplitButton";
import { ObjectRole, useFileAccessControlListHasRoleQuery, useReindexFilesMutation } from "GraphQL/Generated/Apollo";
import { EditFilesInWorkspaceDialog } from "./EditFilesInWorkspaceDialog";

// *********************************************
// Types/Interfaces/Constants/Enums
// *********************************************/
export interface IFileMultiSelectActionButtonProps {
  selectedIds: Array<string>;
}

enum MultiSelectAction {
  CopyToWorkspace = "CopyToWorkspace",
  Delete = "Delete",
  NewWorkspace = "NewWorkspace",
  Reindex = "Reindex",
}

// *********************************************
// Component
// *********************************************/
export function FileMultiSelectActionButton(props: IFileMultiSelectActionButtonProps) {
  const { selectedIds } = props;

  const { t } = useTranslation();
  const user = useAuthUser();
  const { data: hasAllDeletePermissions } = useFileAccessControlListHasRoleQuery({
    variables: {
      // User must have permissions for all the selected files
      fileIds: selectedIds,
      // We need one of the following roles
      roles: [ObjectRole.ContentManager, ObjectRole.Owner],
    },
  });

  const [editInWorkspaceOpen, setEditInWorkspaceOpen] = useState(false);
  const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
  const [reindexEnabled, setReindexEnabled] = useState(selectedIds.length > 0);

  const [ReindexFiles] = useReindexFilesMutation();

  useEffect(() => {
    setReindexEnabled(selectedIds.length > 0);
  }, [selectedIds]);

  const multiSelectActions: SplitButtonProps["options"] = [
    // {
    //   label: t("Global.Action.CopyToWorkspace"),
    //   value: MultiSelectAction.CopyToWorkspace,
    // },
    {
      disabled: selectedIds.length === 0,
      label: t("Global.Action.NewWorkspaceFrom"),
      value: MultiSelectAction.NewWorkspace,
    },
  ];

  const reindexFiles = useCallback(async () => {
    // Disable the button to prevent multiple clicks
    setReindexEnabled(false);

    try {
      const response = await ReindexFiles({
        variables: {
          input: {
            fileIds: selectedIds,
          },
        },
      });

      const fileIds = response.data?.reindexFiles.fileIds || [];
      if (fileIds.length > 0 && fileIds.length === selectedIds.length) {
        // Show success message
        toast.success(t("Components.FileMultiSelectActionButton.Reindex.Success", { count: fileIds.length }));
      } else if (fileIds.length > 0) {
        // Show partial success message
        toast.warn(
          t("Components.FileMultiSelectActionButton.Reindex.PartialSuccess", {
            count: fileIds.length,
            total: selectedIds.length,
          }),
        );
      } else {
        // Show error message
        toast.error(t("Components.FileMultiSelectActionButton.Reindex.Error", { count: selectedIds.length }));
      }
    } catch (error) {
      console.error(error);

      // Show error message
      toast.error(t("Components.FileMultiSelectActionButton.Reindex.Error", { count: selectedIds.length }));

      // Re-enable the button on failures, but not success
      setReindexEnabled(true);
    }
  }, [selectedIds]);

  const onMultiSelectAction = useCallback(
    (action: string) => {
      switch (action as MultiSelectAction) {
        case MultiSelectAction.CopyToWorkspace:
          // TODO: Open dialog for user to search/choose workspace to copy the selected items to
          break;
        case MultiSelectAction.Delete:
          setBulkDeleteDialogOpen(true);
          break;
        case MultiSelectAction.NewWorkspace:
          setEditInWorkspaceOpen(true);
          break;
        case MultiSelectAction.Reindex:
          reindexFiles();
          break;
        default:
          console.warn("Unknown action", action);
          break;
      }
    },
    [reindexFiles, selectedIds],
  );

  const onCloseEditInWorkspaceDialog = useCallback(() => {
    setEditInWorkspaceOpen(false);
  }, []);

  // NOTE: Technically we shouldn't require write permissions in addition to file ACL delete permissions,
  // but currently they go hand in hand
  if (hasRolePermission(user.permissions, Permissions.PatronLibraryWrite)) {
    multiSelectActions.push({
      value: OPTION_VALUE_DIVIDER,
      label: "",
    });
    multiSelectActions.push({
      disabled: selectedIds.length === 0 || !hasAllDeletePermissions?.hasRoleForFiles === true,
      label: t("Global.Action.Delete"),
      value: MultiSelectAction.Delete,
    });
  }

  // Only show reindex option if user has "PlatformDataWrite" permission
  if (hasRolePermission(user.permissions, Permissions.PlatformDataWrite)) {
    multiSelectActions.push({
      disabled: !reindexEnabled,
      label: t("Global.Action.ReindexFiles", { count: selectedIds.length }),
      value: MultiSelectAction.Reindex,
    });
  }

  return (
    <>
      <SplitButton options={multiSelectActions} handleClick={onMultiSelectAction} />

      {editInWorkspaceOpen && (
        <EditFilesInWorkspaceDialog fileIds={selectedIds} open={editInWorkspaceOpen} onClose={onCloseEditInWorkspaceDialog} />
      )}

      {bulkDeleteDialogOpen && (
        <BulkDeleteFileDialog open={bulkDeleteDialogOpen} onClose={() => setBulkDeleteDialogOpen(false)} fileIds={selectedIds} />
      )}
    </>
  );
}
